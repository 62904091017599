<template>
  <div class="goods-wrap" v-if="list">
    <van-swipe
      class="my-swipe goods_swiper"
      :autoplay="30000"
      @change="goodsCurFoo"
      :show-indicators="false"
    >
      <van-swipe-item
        class="flex-container goods-swiper_item"
        v-for="(image, index) in list"
        :key="index"
      >
        <div
          class="flex-container list"
          @click="
            $router.push({
              name: 'HomeGoodsdetail',
              query: { goods_id: image.goods_id, inviter_id: k_id, k: k_id },
            })
          "
        >
          <img v-lazy="image.goods_image" class="img" />
          <div class="info flex1">
            <h2>
              {{ image.goods_name }}
            </h2>
            <div class="price">
              <span>￥{{ image.goods_price }}</span>
              <b>{{ image.goods_marketprice }}</b>
            </div>
          </div>
          <div class="control">立即购买</div>
        </div>
      </van-swipe-item>
    </van-swipe>

    <div class="cur flex-container" v-if="list && list.length > 1">
      <span
        :class="{
          act: goodsCur == index,
          default: goodsCur != index,
        }"
        v-for="(item, index) in list.length"
        :key="index"
      ></span>
    </div>
  </div>
</template>

<script>
import { Swipe, SwipeItem } from "vant";

export default {
  components: {
    vanSwipe: Swipe,
    vanSwipeItem: SwipeItem,
  },
  methods: {
    goodsCurFoo(index) {
      this.goodsCur = index;
    },
  },
  props: {
    list: {},
    k_id: {},
  },
  data() {
    return {
      goodsCur: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
$fs: 24;
$lh: 34;
.goods-wrap {
  margin-top: rem(40);
  margin-bottom: rem(30);
}
.cur {
  margin: rem(10) auto 0;
  justify-content: center;
  align-items: center;
  .default {
    background: rgba(0, 0, 0, 0.2);
  }
  .act {
    background: #ff4848;
  }
  span {
    width: rem(10);
    height: rem(10);
    display: inline-block;
    border-radius: 50%;

    margin: 0 rem(5);
  }
}
.goods_swiper {
  width: rem(690);
  margin: 0 auto;
  background: #f8f8f8;
  height: rem(156);
  border-radius: rem(16);
  .goods-swiper_item {
    padding: 0 rem(20);
    box-sizing: border-box;
    align-items: center;
  }
  .list {
    align-items: center;
    background: #f8f8f8;
    width: rem(690);
    .img {
      width: rem(116);
      height: rem(116);
      border-radius: rem(8);
    }
    .control {
      display: block;
      background: #ff4848;
      border-radius: rem(8);
      width: rem(156);
      height: rem(64);
      font-size: rem(30);
      line-height: rem(64);
      text-align: justify;
      color: #ffffff;
      text-align: center;
    }
    .info {
      margin: 0 rem(30) 0 rem(20);
      font-size: rem($fs);
      line-height: rem($lh);
      text-align: justify;
      color: #333333;
      h2 {
        height: rem(68);
        @include ell2;
      }
      .price {
        span {
          font-size: rem(32);
          line-height: rem(45);
          text-align: justify;
          color: #ff4848;
          font-weight: bold;
          margin-right: rem(20);
        }
        b {
          font-size: rem($fs);
          line-height: rem($lh);
          color: #999999;
          text-align: justify;

          text-decoration-line: line-through;
        }
      }
    }
  }
}

.van-swipe__indicators {
  bottom: rem(100);
}
</style>
