<template>
  <div>
    <div v-if="skShow == false">
      <van-skeleton title avatar />
      <van-skeleton title class="swiper-sk" />
      <van-skeleton title class="goods-sk" />
      <van-skeleton title class="h1-sk" row="7" />
    </div>

    <div class="bbsMall-media" v-else v-cloak>
      <oBar :list="goodsArticleInfo.author"></oBar>
      <oMedia :list="goodsArticleInfo"></oMedia>
      <oGoods
        :list="goodsArticleInfo.goods_list"
        :k_id="goodsArticleInfo.create_member_id"
      ></oGoods>
      <oArticle :list="goodsArticleInfo"></oArticle>
    </div>
  </div>
</template>

<script>
import { Skeleton } from "vant";
import oBar from "../components/bar";
import oMedia from "./components/media";
import oGoods from "./components/goods";
import oArticle from "./components/article";
import { get } from "@get/http.js";
export default {
  data() {
    return {
      skShow: false,
      goodsArticleInfo: {},
      nextInfo: {},
      prevInfo: {},
      swiperOption: {
        direction: "vertical",
        slidesPerView: "auto",
        paginationClickable: true,
        freeMode: true,

        scrollbar: {
          el: ".swiper-scrollbar",
        },
        // preventInteractionOnTransition: true,
      },
      f: {
        direction: "vertical",
        preventInteractionOnTransition: true,
        freeModeMinimumVelocity: 1,
        touchRatio: 0.01,
      },
    };
  },
  async created() {
    let data = await get(`/GoodsArticle/show`, {
      params: {
        id: this.$route.query.a_id,
      },
    });

    this.goodsArticleInfo = data.goodsArticleInfo;
    this.nextInfo = data.nextInfo;
    this.prevInfo = data.prevInfo;
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.skShow = true;
      }, 500);
    });
  },
  components: {
    vanSkeleton: Skeleton,
    oBar,
    oMedia,
    oGoods,
    oArticle,
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
[v-cloak] {
  display: none;
}
.bbsMall-media {
  background: #fff;
  overflow: hidden;
  width: 100%;
  // min-height: 100%;
  // height: 100%;
}
.swiper {
  height: 100%;
  & /deep/ .swiper-slide {
    height: auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
}
.swiper-sk {
  padding: 0;
  margin-top: rem(20);
  .van-skeleton__title /deep/ {
    width: 100% !important;
    height: rem(500);
  }
}

.goods-sk {
  margin-top: rem(20);
  .van-skeleton__title /deep/ {
    width: 100% !important;
    height: rem(150);
  }
}
.h1-sk {
  margin-top: rem(20);
  .van-skeleton__title /deep/ {
    width: 100% !important;
  }
}
</style>
