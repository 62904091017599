<template>
  <div class="media">
    <div class="media_img" v-if="dataList.image_video_type == 1">
      <van-swipe
        class="my-swipe media_swiper"
        :autoplay="3000"
        indicator-color="white"
        @change="mediaCurFoo"
        v-if="dataList && dataList.image_list"
      >
        <van-swipe-item
          v-for="(image, index) in dataList.image_list"
          :key="index"
        >
          <img v-lazy="image && image.image_url" />
        </van-swipe-item>
      </van-swipe>
      <div class="media_img-cur">
        {{ mediaCur }}/{{ dataList.image_list.length }}
      </div>
    </div>
    <div class="media_video" v-else>
      <video
        webkit-playsinline="true"
        preload="none"
        controls
        playsinline="true"
        :style="{
          width: video.w,
          height: video.h,
        }"
        :src="dataList.video_url"
        ref="video"
        id="video"
        v-show="coverBoo"
      ></video>
      <img
        ref="cover"
        id="cover"
        v-if="!coverBoo"
        :src="
          dataList &&
            dataList.video_index_image &&
            dataList.video_index_image.image_url
        "
        class="playCover"
      />

      <img
        v-if="!coverBoo"
        src="@image/bbsMall/kk_icon/play_icon-big.png"
        class="playVideo"
        @click="playVideo"
      />
      <!-- <div class="media_videoProgress">
        <div class="ing"></div>
      </div> -->
    </div>

    <!-- <div class="media_control flex-container">
      <div class="m-d-goods flex-container flex1">
        <div class="flex-container public_Icon">
          <img
            src="@image/bbsMall/kk_icon/Vector_icon.png"
            v-if="goodAct"
            @click="
              goodAct = !goodAct;
              goodsNum++;
            "
          />
          <img
            src="@image/bbsMall/kk_icon/Vector_icon_act.png"
            v-else
            @click="
              goodAct = !goodAct;
              goodsNum--;
            "
          />
        </div>
        <p>{{ goodsNum }}</p>
      </div>
      <div class="m-d-review">
        <div class="flex-container public_Icon">
          <img src="@image/bbsMall/kk_icon/pinglun_icon.png" />
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { Swipe, SwipeItem } from "vant";

export default {
  components: {
    vanSwipe: Swipe,
    vanSwipeItem: SwipeItem,
  },
  props: {
    list: {},
  },

  data() {
    return {
      mediaCur: 1,
      dataList: {},
      video: {
        w: "100%",
        h: "375px",
      },
      coverBoo: false,
      goodAct: false,
      goodsNum: 8888,
    };
  },
  watch: {
    list() {
      this.dataList = this.list;

      this.heightFoo();
    },
  },
  created() {
    this.dataList = this.list;
    this.heightFoo();
  },
  mounted() {},
  methods: {
    heightFoo() {
      if (this.list && this.list.image_video_type == 2) {
        let arr = this.list.video_index_image.resolution.split("*");
        let con = document.body.clientWidth / (arr[0] / 2);
        if (con != 0) {
          this.video = {
            w: document.body.clientWidth + "px",
            h: (arr[1] / 2) * con + "px",
          };
        } else {
          this.video = {
            w: document.body.clientWidth + "px",
            h: arr[1] / 2 + "px",
          };
        }
      }
    },
    mediaCurFoo(index) {
      this.mediaCur = index + 1;
    },

    playVideo() {
      this.coverBoo = true;
      let v = this.$refs.video;
      v.play();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.media {
  width: 100%;
  position: relative;
}

.media_img {
  position: relative;
  .media_img-cur {
    position: absolute;
    top: rem(21);
    right: rem(33);
    width: rem(64);
    height: rem(32);
    background: rgba(0, 0, 0, 0.4);
    border-radius: rem(16);
    text-align: center;
    color: #ffffff;
    font-size: rem(22);
    line-height: rem(32);
  }
}
.media_swiper {
  width: rem(750);
  margin: 0 auto;
  img {
    width: rem(750);
  }
}
.public_Icon {
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: rem(62);
  height: rem(62);
  img {
    width: rem(32);
    height: rem(32);
  }
}
.media_control {
  z-index: 9999;
  position: absolute;
  bottom: rem(40);
  box-sizing: border-box;
  padding: 0 rem(50);
  width: 100%;
  .m-d-goods {
    align-items: center;
    .flex-container {
      background: #ff4848;
      margin-right: rem(14);
    }

    p {
      text-align: center;
      // width: rem(81);
      height: rem(31);
      padding: 0 rem(14);
      color: #666;
      font-size: rem(22);
      line-height: rem(31);
      background: rgba(255, 255, 255, 0.7);
      border-radius: rem(16);
    }
  }

  .m-d-review {
    .flex-container {
      background: #fff;
    }
  }
}

.media_video {
  position: relative;
  .playCover {
    clear: both;
    width: 100%;
  }
  .playVideo {
    width: rem(100);
    height: rem(100);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    margin: auto;
  }
}

.media_videoProgress {
  width: 100%;
  height: rem(4);
  position: relative;
  background: rgba(0, 0, 0, 0.2);
  .ing {
    background: #ff4848;
    width: 20%;
    height: rem(4);
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>

<style lang="scss">
/*video默认全屏按钮*/
video::-webkit-media-controls-fullscreen-button {
  display: none !important;
}

/*video默认aduio音量按钮*/
video::-webkit-media-controls-mute-button {
  display: none !important;
}

/*video默认setting按钮*/
video::-internal-media-controls-overflow-button {
  display: none !important;
}

/*腾讯云点播禁用firefox全屏、设置按钮*/
.trump-button[sub-component="fullscreen_btn"],
.trump-button[now="fullscreen"] {
  display: none !important;
}
.trump-button[sub-component="setting"] {
  display: none !important;
}

/*禁用video的controls（要慎重！不要轻易隐藏掉，会导致点击视频不能播放）*/
video::-webkit-media-controls {
  display: none !important;
}
</style>
