<template>
  <div class="article">
    <div class="content">
      <h2>{{ list.title }}</h2>
      <div v-html="list.content"></div>
    </div>
    <!-- <div style="height: 50px; width: 100%"></div> -->
    <div class="tips">
      <!-- <img src="@/assets/image/bbsMall/kk_icon/Group_icon.png" /> -->
      <!-- <p>往上滑动可快速切换</p> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {},
  },
};
</script>
<style lang="scss">
@import "~@css/mixin.scss";
.content {
  blockquote {
    display: block;
    border-left: 8px solid #d0e5f2;
    padding: 5px 10px;
    margin: 10px 0;
    line-height: 1.4;
    font-size: 100%;
    // background-color: #f1f1f1;
  }
  table {
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
  }
  th {
    border-bottom: 2px solid #ccc;
    text-align: center;
    background-color: #f1f1f1;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    padding: 3px 5px;
    min-height: 30px;
  }
  td {
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    padding: 3px 5px;
    min-height: 30px;
  }

  p {
    font-size: rem(32) !important;
    line-height: rem(50);
    text-align: justify;
    color: #555555;
  }
  li {
    list-style-type: decimal;
  }
  b {
    font-weight: bold;
  }
  i {
    font-style: italic;
  }
  .eleImg {
    width: rem(50);
  }
}
</style>
<style lang="scss" scoped>
@import "~@css/mixin.scss";

.article {
  margin: 0 rem(30);
}
h2 {
  font-size: rem(34);
  line-height: rem(48);
  font-weight: bold;
  text-align: justify;
  margin-bottom: rem(20);
  color: #333333;
}

.tips {
  margin: rem(150) 0 rem(20);
  img {
    width: rem(14);
    display: block;
    height: rem(14);
    margin: 0 auto;
  }
  p {
    font-size: rem(22);
    line-height: rfen(31);
    text-align: justify;
    color: #999999;
    text-align: center;
  }
}
</style>
