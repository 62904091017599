<template>
  <div class="components_bar flex-container">
    <img
      class="bar_goReturn"
      @click="$router.go(-1)"
      src="@image/bbsMall/kk_icon/Vector 20_icon.png"
    />

    <img
      v-if="show"
      class="bar_tx"
      :src="list && list.author_avatar"
      @click="goRouter('bbsMall_Me', { author_id: list.id })"
    />

    <p v-if="show" @click="goRouter('bbsMall_Me', { author_id: list.id })">
      {{ list && list.author_name }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    list: {},
    show: {
      default: true,
    },
  },
  methods: {
    goRouter(name, q = {}) {
      this.$router.push({ name, query: q });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";

.components_bar {
  height: rem(88);
  align-items: center;
  background: #fff;
  padding: 0 rem(32);
  box-sizing: border-box;
  .bar_goReturn {
    width: rem(15);
    height: rem(29);
    margin-right: rem(40);
  }
  .bar_tx {
    width: rem(54);
    height: rem(54);
    border-radius: 50%;
    margin-right: rem(10);
  }
  p {
    font-size: rem(32);
    line-height: rem(45);
    color: #333333;
    @include ell;
  }
}
</style>
